<template>
  <sidenav :class="curClasses" :orientation="orientation">

    <!-- Inner -->
    <div :class="{ 'py-1': orientation !== 'horizontal' }" class="sidenav-inner">

      <sidenav-router-link :exact="true" icon="ion ion-ios-speedometer" to="/">Ana Sayfa</sidenav-router-link>
      <sidenav-router-link :exact="true" icon="ion ion-md-list" to="/harfler">Harfler</sidenav-router-link>
      <sidenav-router-link :exact="true" icon="ion ion-ios-list" to="/ikililer">İkililer</sidenav-router-link>
      <sidenav-router-link :exact="true" icon="ion ion-md-list-box" to="/kelimeler">Kelimeler</sidenav-router-link>
      <sidenav-router-link :exact="true" icon="ion ion-ios-list-box" to="/ligalar">Ligalar</sidenav-router-link>
      <sidenav-router-link :exact="true" icon="ion ion-md-checkbox-outline" to="/kurallar">Kurallar</sidenav-router-link>
      <sidenav-router-link :exact="true" icon="ion ion-md-text" to="/metin">Metin İşlemleri</sidenav-router-link>
      <sidenav-router-link :exact="true" icon="ion ion-md-cloud-download" to="/indirme">İndirme İşlemleri</sidenav-router-link>

    </div>
  </sidenav>
</template>

<script>
import {
  Sidenav,
  SidenavBlock,
  SidenavDivider,
  SidenavHeader,
  SidenavLink,
  SidenavMenu,
  SidenavRouterLink
} from '@/vendor/libs/sidenav'

export default {
  name: 'app-layout-sidenav',
  components: {
    /* eslint-disable vue/no-unused-components */
    Sidenav,
    SidenavLink,
    SidenavRouterLink,
    SidenavMenu,
    SidenavHeader,
    SidenavBlock,
    SidenavDivider
    /* eslint-enable vue/no-unused-components */
  },

  props: {
    orientation: {
      type: String,
      default: 'vertical'
    }
  },

  computed: {
    curClasses() {
      let bg = this.layoutSidenavBg

      if (this.orientation === 'horizontal' && (bg.indexOf(' sidenav-dark') !== -1 || bg.indexOf(' sidenav-light') !== -1)) {
        bg = bg
          .replace(' sidenav-dark', '')
          .replace(' sidenav-light', '')
          .replace('-darker', '')
          .replace('-dark', '')
      }

      return `bg-${bg} ` + (
        this.orientation !== 'horizontal'
          ? 'layout-sidenav'
          : 'layout-sidenav-horizontal container-p-x flex-grow-0'
      )
    }
  },

  methods: {
    isMenuActive(url) {
      return this.$route.path.indexOf(url) === 0
    },

    isMenuOpen(url) {
      return this.$route.path.indexOf(url) === 0 && this.orientation !== 'horizontal'
    },

    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    }
  }
}
</script>
