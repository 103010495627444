<template>
  <b-navbar :variant="getLayoutNavbarBg()" class="layout-navbar align-items-lg-center container-p-x" toggleable="lg">

    <!-- Brand -->
    <b-navbar-brand to="/">{{ this.appName() }}</b-navbar-brand>

    <!-- Sidenav toggle -->
    <b-navbar-nav v-if="sidenavToggle" class="align-items-lg-center mr-auto mr-lg-4">
      <a class="nav-item nav-link px-0 ml-2 ml-lg-0" href="javascript:void(0)" @click="toggleSidenav">
        <i class="ion ion-md-menu text-large align-middle"/>
      </a>
    </b-navbar-nav>

    <!-- Navbar toggle -->
    <b-navbar-toggle target="app-layout-navbar"></b-navbar-toggle>

    <b-collapse id="app-layout-navbar" is-nav>
      <b-navbar-nav class="align-items-lg-center">
        <!--  <b-nav-item href="#">Link 1</b-nav-item>
          <b-nav-item href="#">Link 2</b-nav-item>-->
      </b-navbar-nav>
    </b-collapse>

  </b-navbar>
</template>

<script>
export default {
  name: 'app-layout-navbar',

  props: {
    sidenavToggle: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    appName() {
      return 'Fontmatik'
    },
    toggleSidenav() {
      this.layoutHelpers.toggleCollapsed()
    },

    getLayoutNavbarBg() {
      return this.layoutNavbarBg
    }
  }
}
</script>
