// Additional polyfills
import 'custom-event-polyfill'
import 'url-polyfill'

import Vue from 'vue'
import App from './App'
import router from './router'

import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import globals from './globals'
import Popper from 'popper.js'
import moment from 'moment'
import store from '@/store'
import Notifications from 'vue-notification'
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
// Required to enable animations on dropdowns/tooltips/popovers
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Multiselect)
Vue.use(Notifications)
Vue.use(VueSweetalert2);

// Global RTL flag
Vue.mixin({
  data: globals,
  methods: {
    formatTarih: function (value) {
      if (!value) {
        return ''
      }
      return moment(value).format('DD-MM-YYYY')
    },
    formatDurum: function (value) {
      switch (value) {
        case 'h':
          return "Harf"
        case 'm':
          return "Mustakil"
        case 'b':
          return "Başta"
        case 'o':
          return "Orta"
        case 's':
          return "Sonda"
      }
      if (!value) {
        return ""
      }

    },
    async mesajGoster(msg, icon = "success") {
      await this.$swal({
        icon: icon,
        confirmButtonText: "Tamam",
        title: msg,
      });
    },
    async hataGoster(msg) {
      await this.$swal({
        icon: "error",
        confirmButtonText: "Tamam",
        html: msg,
      });
    },
    dizidekiIcerigiGuncelle(dizi, kayit) {
      let bulundu = false
      const yeniSatirlar = [...dizi]
      for (let i = 0; i < yeniSatirlar.length; i++) {
        if (yeniSatirlar[i].id === kayit.id) {
          bulundu = true
          for (let pro in kayit) {
            yeniSatirlar[i][pro] = kayit[pro]
          }
          break
        }
      }
      if (!bulundu) {
        yeniSatirlar.push(kayit)
      }
      return yeniSatirlar
    },

    diziObjEx(dizi, objisim) {
      const yeniSatirlar = []
      for (let i = 0; i < dizi.length; i++) {
        let yeni = {}
        for (let pro in dizi[i]) {
          if (pro === objisim) {
            let obj = dizi[i][pro]
            for (let po in obj) {
              yeni[po] = obj[po]
            }
          } else {
            yeni[pro] = dizi[i][pro]
          }
        }
        yeniSatirlar.push(yeni)

      }

      return yeniSatirlar
    }
  },
})

window.vueapp = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
