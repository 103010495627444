import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'
import NotFound from '@/components/NotFound'

import globals from '@/globals'

// Layouts
import Layout1 from '@/layout/Layout1'
import LayoutBlank from "@/layout/LayoutBlank";

Vue.use(Router)
Vue.use(Meta)

const router = new Router({
  base: '/',
  mode: 'history',
  routes: [{
    path: '/',
    component: Layout1,
    children: [{
      path: '',
      component: () => import('@/views/AnaSayfa')
    }, {
      path: 'harfler',
      component: () => import('@/views/HarflerSayfa')
    }, {
      path: 'ikililer',
      component: () => import('@/views/IkililerSayfa')
    }, {
      path: 'kelimeler',
      component: () => import('@/views/KelimelerSayfa')
    }, {
      path: 'ligalar',
      component: () => import('@/views/LigalarSayfa')
    }, {
      path: 'kurallar',
      component: () => import('@/views/KurallarSayfa')
    }, {
      path: 'metin',
      component: () => import('@/views/MetinIslemleriSayfa')
    }, {
      path: 'indirme',
      component: () => import('@/views/IndirmeIslemleriSayfa')
    }]
  }, {
    path: '/giris-yap',
    component: LayoutBlank,
    children: [
      {
        path: '',
        component: () => import('@/views/GirisYapSayfa')
      }
    ]
  },
    {
      // 404 Not Found page
      path: '*',
      component: NotFound
    }]
})

router.afterEach(() => {
  // On small screens collapse sidenav
  if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
    setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  }

  // Scroll to top of the page
  globals().scrollTop(0, 0)
})

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add('app-loading')

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10)
})

export default router
