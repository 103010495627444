import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    kisi: JSON.parse(localStorage.getItem('kisi') || '{}'),

  },
  getters: {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    kisi: state => state.kisi
  },
  mutations: {
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, payload) {

      state.status = 'success'
      state.token = payload.token
      state.kisi = payload.kisi
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
      state.kisi = {}
    },

  },
  actions: {
    auth_success({commit}, data) {
      console.log("auth_success",data.token)
      localStorage.setItem('token', data.token)
      localStorage.setItem('kisi', JSON.stringify(data.kisi))
      axios.defaults.headers.common['Authorization'] = data.token
      commit('auth_success', {
        token: data.token,
        kisi: data.kisi
      })
    },

    logout({commit}) {
      return new Promise((resolve, reject) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('kisi')

        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    }

  },
  modules: {}
})
