<template>
  <nav :class="getLayoutFooterBg()" class="layout-footer footer">
    <div class="container-fluid container-p-x pb-3">
      <!--  <a href="#" class="footer-link pt-3">Link 1</a>
        <a href="#" class="footer-link pt-3 ml-4">Link 2</a>-->
    </div>
  </nav>
</template>

<script>
export default {
  name: 'app-layout-footer',

  methods: {
    getLayoutFooterBg() {
      return `bg-${this.layoutFooterBg}`
    }
  }
}
</script>
