import layoutHelpers from '@/layout/helpers.js'

export default function () {
  return {
    // Public url
    publicUrl: process.env.BASE_URL,
    API_URL: process.env.VUE_APP_BASE_API_URL + "/api/",
    cacheKey: '1',
    // Layout helpers
    layoutHelpers,

    yuklendi: false,
    icerikYukleniyor: true,
    data: {},
    hataVarMi: false,
    mesaj: '',
    silOnay: false,


    get idParam() {
      return this.getIntParam('id');
    },

    get kayitButonMetin() {
      return this.idParam == 0 ? "Kaydet" : "Güncelle";
    },
    getIntParam(param, varsayilan) {
      const urlParams = new URLSearchParams(window.location.search);
      return parseInt(urlParams.get(param)) || varsayilan || 0;
    },

    urlServerParams(serverParams) {
      const urlParams = new URLSearchParams();
      urlParams.set("sortField", serverParams.sortField);
      urlParams.set("sortType", serverParams.sortType);
      urlParams.set("page", serverParams.page);
      urlParams.set("perPage", serverParams.perPage);
      urlParams.set("search", serverParams.search);

      /*for (const key in serverParams.columnFilters) {
        urlParams.set("columnFilters[" + key + "]", serverParams.columnFilters[key])
      }     */

      for (const key in serverParams.columnFilters) {
        if (serverParams.columnFilters[key]) {
          urlParams.set("searchFields", key)
          urlParams.set("searchTerms", serverParams.columnFilters[key])
        }
      }
      return urlParams.toString()
    },
    getStrParam(param, varsayilan) {
      const urlParams = new URLSearchParams(window.location.search);
      return urlParams.get(param) || varsayilan || '';
    },
    get path() {
      return document.location.pathname + document.location.search;
    },
    get apipath() {
      return document.location.pathname + document.location.search;
    },

    // Check for RTL layout
    get isRtlMode() {
      return document.documentElement.getAttribute('dir') === 'rtl' ||
        document.body.getAttribute('dir') === 'rtl'
    },

    // Check if IE
    get isIEMode() {
      return typeof document.documentMode === 'number'
    },

    // Check if IE10
    get isIE10Mode() {
      return this.isIEMode && document.documentMode === 10
    },

    // Layout navbar color
    get layoutNavbarBg() {
      return 'navbar-theme'
    },

    // Layout sidenav color
    get layoutSidenavBg() {
      return 'sidenav-theme'
    },

    // Layout footer color
    get layoutFooterBg() {
      return 'footer-theme'
    },

    // Animate scrollTop
    scrollTop(to, duration, element = document.scrollingElement || document.documentElement) {
      if (element.scrollTop === to) return
      const start = element.scrollTop
      const change = to - start
      const startDate = +new Date()

      // t = current time; b = start value; c = change in value; d = duration
      const easeInOutQuad = (t, b, c, d) => {
        t /= d / 2
        if (t < 1) return c / 2 * t * t + b
        t--
        return -c / 2 * (t * (t - 2) - 1) + b
      }

      const animateScroll = () => {
        const currentDate = +new Date()
        const currentTime = currentDate - startDate
        element.scrollTop = parseInt(easeInOutQuad(currentTime, start, change, duration))
        if (currentTime < duration) {
          requestAnimationFrame(animateScroll)
        } else {
          element.scrollTop = to
        }
      }

      animateScroll()
    }
  }
}
